@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Urbanist;
  src: url('./assets/fonts/Urbanist-Regular.ttf');
}

.font-Urbanist {
  font-family: Urbanist;
}

@font-face {
  font-family: Urbanist-Bold;
  src: url('./assets/fonts/Urbanist-Bold.otf');
}

.font-Urbanist-Bold {
  font-family: Urbanist-Bold;
}

@font-face {
  font-family: Montserrat;
  src: url('./assets/fonts/Montserrat-Regular.ttf');
}

.font-Montserrat {
  font-family: Montserrat;
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('./assets/fonts/Montserrat-Bold.ttf');
}

.font-Montserrat-Bold {
  font-family: Montserrat-Bold;
}


@font-face {
  font-family: Inter;
  src: url('./assets/fonts/Inter-Regular.otf');
}

.font-Inter {
  font-family: Inter;
}


progress {
  border-radius: 40px;
  width: 100%;
  height: 16px;
}

progress::-webkit-progress-bar {
  background-color: #F4F4F6;
  border-radius: 8px;
}

progress::-webkit-progress-value {
  border-radius: 8px;
  background-color: #52BC1F;
}

progress::-moz-progress-bar {
  /* style rules */
}


::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #E5E5E5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 20px #929090;
}

.Toastify__toast-container {
  z-index: 10001 !important;
}

/* custom styles */
.rdw-link-modal {
  height: fit-content !important;
}

div:where(.swal2-container) {
  z-index: 10060 !important;
}